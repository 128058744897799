import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'


const HomePage = ({data}) => {
  
  const { allMarkdownRemark } = data
  const edges = allMarkdownRemark.edges 
  const projects = edges.map(edge => edge.node)

  return (
  <Layout>
    <SEO title="Opensource - INMAGIK" />
    <div className="jumbo-title jumbo-small p-4 container-fluid text-center bg-black">
      <div className="display-3 font-200 text-white">Open source</div>
      {/* <h1 className="display-4">INMAGIK</h1> */}
      <div className="row my-4">
        <div className="col-md-6 offset-md-3 text-center">
          <p className="lead font-200 text-white">
            INMAGIK promotes the use and development of open source tools and libraries,
            and free access to knowledge and technology.
          </p>
        </div>
      </div>
    </div>

    
    <div className="bg-s">
      <div className="main-content container">
        <div className="row my-3">
          {projects.map((project, i) => (<div key={i} className="col-md-8 offset-md-2 p-4 text-left border-bottom">
            <p className="display-medium font-200 mb-0">
              {project.frontmatter.title}
            </p>
            {project.frontmatter.version && <h3 className="font-200">v.{project.frontmatter.version}</h3>}
            <p className="lead font-200" dangerouslySetInnerHTML={{ __html: project.html }} >
            </p>
            <p className="lead">
              {project.frontmatter.home && <a target="_blank" className="text-primary d-block" href={project.frontmatter.home}>Visit project homepage</a>}
              {project.frontmatter.repo && <a target="_blank" className="text-primary d-block" href={project.frontmatter.repo}>See repository</a>}
            </p>
          </div> ))}
        </div>
      </div>
    </div>

 
 
    
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)}

export default HomePage

export const query = graphql`
  query {
  file {
    id
  }
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(opensource)/"}}
  ) {
    nodes {
      id
      html
    }
    edges {
      node {
        id
        html,
        frontmatter {
          home
          repo
          title
          version
        }
      }
    }
  }
}
`